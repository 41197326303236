var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-wrapper',[_c('v-row',{staticClass:"top-controls",attrs:{"dense":""}},[_c('v-select',{staticClass:"month",attrs:{"items":_vm.availableMonths,"label":"Month"},on:{"change":_vm.fetchShifts},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}}),_c('job-select',{staticClass:"job-select",attrs:{"add-all-option":""},on:{"change":_vm.fetchShifts},model:{value:(_vm.jobID),callback:function ($$v) {_vm.jobID=$$v},expression:"jobID"}}),_c('v-checkbox',{attrs:{"label":"Outstanding"},on:{"change":_vm.fetchShifts},model:{value:(_vm.outstanding),callback:function ($$v) {_vm.outstanding=$$v},expression:"outstanding"}}),_c('v-checkbox',{attrs:{"label":"Flagged"},on:{"change":_vm.fetchShifts},model:{value:(_vm.flagged),callback:function ($$v) {_vm.flagged=$$v},expression:"flagged"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"680px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"export",attrs:{"color":"primary","depressed":"","disabled":_vm.selectedDays.size === 0},on:{"click":_vm.exportSelected}},'v-btn',attrs,false),[_vm._v("Export")])]}}]),model:{value:(_vm.showExport),callback:function ($$v) {_vm.showExport=$$v},expression:"showExport"}},[_c('shoot-export',{attrs:{"days":_vm.exportDays},on:{"close":function($event){_vm.showExport = false},"mark-invoiced":_vm.markInvoiced}})],1)],1),(_vm.days.length === 0)?_c('div',{staticClass:"text-center text--lighten-3 ma-4"},[_c('h3',{staticClass:"font-italic grey--text"},[_vm._v("No jobs to display")])]):_vm._e(),_vm._l((_vm.days),function(ref){
var uid = ref.uid;
var target = ref.target;
var day = ref.day;
var job = ref.job;
var shifts = ref.shifts;
var invoiced = ref.invoiced;
return [_c('shoot-day',{key:job.number+day,attrs:{"uid":uid,"target":target,"day":day,"job":job,"shifts":shifts,"invoiced":invoiced},on:{"select-day":_vm.selectDay}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }