var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-wrapper',{attrs:{"id":"overview"}},[_c('v-data-table',{attrs:{"loading":this.data === null,"headers":_vm.headers,"items":_vm.items,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-sheet',{staticClass:"d-flex align-center",attrs:{"tile":"","height":"54"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":_vm.previousWeek}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":_vm.nextWeek}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),_c('v-toolbar-title',{staticClass:"ma-2"},[_vm._v(" Week of "+_vm._s(_vm.startDay.format('dddd D MMMM'))+" ")]),_c('v-spacer'),_c('v-checkbox',{attrs:{"label":"Only working"},model:{value:(_vm.onlyWorking),callback:function ($$v) {_vm.onlyWorking=$$v},expression:"onlyWorking"}})],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('user-working-icon',{attrs:{"working":item.days[0].working},on:{"click":function($event){return _vm.toggleWeekWorking(item)}}})],1),_c('td',[_c('router-link',{staticClass:"text-decoration-none black--text hover-underline",attrs:{"to":{ name: 'user-calendar', params: { userSlug: _vm.slugify(item.name) } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),(item.days)?_vm._l((item.days),function(day,d){return _c('td',{key:d,class:{ off: !day.working }},_vm._l((day.entries),function(entry){
var _obj;
return _c('router-link',{key:((item.userID) + "+" + d + "+" + (entry.job_number)),staticClass:"text-decoration-none",attrs:{"to":{ name: 'user-calendar', params: { userSlug: _vm.slugify(item.name), atDate: _vm.headers[d + 2].value } }}},[_c('v-chip',{staticClass:"mr-1 hover-pointer",class:( _obj = {}, _obj[entry.type] = true, _obj ),style:({
                'background-color': entry.type === 'shoot' ? '#222' : _vm.jobColours[entry.job_number],
                'border-color': _vm.jobColours[entry.job_number]
              }),attrs:{"x-small":"","text-color":"#fff"}},[_vm._v(" "+_vm._s(entry.type === 'leave' ? 'Leave' : entry.job_number)+" ")])],1)}),1)}):[_vm._v(" asdfafds "),_vm._l((7),function(d){return _c('td',{key:d})})]],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }