<template>
  <v-menu bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </span>
    </template>
    <div class="block-edit-menu">
      <div v-if="block.strike" @click="change('strike', false)">
        Unstrike
      </div>
      <div v-else @click="change('strike', true)">
        Strike
      </div>
      <div v-if="block.internal" @click="change('internal', false)">
        Unmark internal
      </div>
      <div v-else @click="change('internal', true)">
        Mark internal
      </div>
      <template v-if="block.amendment">
        <div @click="remove">
          Delete
        </div>
      </template>
    </div>
  </v-menu>
</template>

<script>
import * as api from '../../api'

export default {
  name: 'BlockEditMenu',
  props: {
    block: Object,
    shift: Object
  },
  methods: {
    async change(action, action_direction) {
      await api.post('/shoot/amendment', {
        entry_id: this.shift.entry_id,
        action,
        action_direction,
        block_name: this.block.name
      })
      this.$emit('update')
    },
    async remove() {
      await api.put('/shoot/amendment/delete', {
        amendment_id: this.block.amendment_id,
        shift_id: this.shift.id
      })
      this.$emit('update')
    }
  }
}
</script>

<style scoped lang="scss">
.block-edit-menu {
  font-size: 0.9rem;
  background: #fff;
  padding: 4px 0;

  > div {
    padding: 4px 8px;
    &:hover {
      cursor: pointer;
      background: #eee;
    }
  }
}
</style>
