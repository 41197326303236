<template>
<div>
  <div class="d-flex align-center">
    <v-checkbox @change="$emit('select-day', uid, $event)">
      <template v-slot:label>
        <h4 class="black--text">{{ day }}</h4>
      </template>
    </v-checkbox>
    <v-spacer></v-spacer>
    <h3>
      <span class="font-weight-regular">{{ job.number }}</span> {{ job.name }}
      <v-icon class="mr-2" :title="target">{{ entryIcons[target] }}</v-icon>
    </h3>
    <v-spacer></v-spacer>
    <v-checkbox label="Invoiced" class="v-input--reverse"
                :input-value="invoiced"
                @change="invoicedChanged(uid, $event)">
    </v-checkbox>
  </div>
  <shoot-table :shifts="shifts"></shoot-table>
</div>
</template>

<script>
import * as api from '../../api'
import ShootTable from './ShootTable'

export default {
  name: 'ShootDay',
  components: { ShootTable },
  props: {
    uid: String,
    target: String,
    day: String,
    job: Object,
    shifts: Array,
    invoiced: Boolean
  },
  methods: {
    async invoicedChanged(uids, value) {
      await api.put('/invoiced', { uids, value })
      // TODO: what's this doing here?
      // if (this.outstanding) {
      //   await this.fetchShifts()
      // }
    }
  }
}
</script>

<style scoped>

</style>
