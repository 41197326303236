<template>
<v-simple-table dense class="shifts">
  <template v-slot:default>
    <thead>
    <tr>
      <th class="name">Name</th>
      <th class="narrow">Times</th>
      <th class="narrow">Hours/Amount</th>
      <th class="description">Description</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <template v-for="shift in shifts">
      <tr v-for="(block, r) in breakdowns[shift.id]" :key="shift.id+'-'+r"
          :class="{internal: block.internal, strike: block.strike }">
        <td>
          <span v-if="r === 0">
            <router-link  class="text-decoration-none black--text" :title="shift.id"
                          :to="{ name: 'user-calendar',
                                 params: { userSlug: slugify(shift.user_name) },
                                 query: { date: extractDate(shift.start_date), entry_uid: shift.uid }
                            }">
              <span class="hover-underline">{{ shift.user_name }} ({{ shift.role | fullTitle }}) </span>
              <span :title="`${shift.outward_vehicle || ''} ${shift.outward_mode} (outward)`" class="travel-icons">
                <v-icon>{{ vehicleIcons[shift.outward_vehicle] }}</v-icon>
                <v-icon>{{ journeyModeIcons[shift.outward_mode] }}</v-icon>
              </span>
              |
              <span :title="`${shift.return_vehicle || ''} ${shift.return_mode} (return)`" class="travel-icons">
                <v-icon>{{ vehicleIcons[shift.return_vehicle] }}</v-icon>
                <v-icon>{{ journeyModeIcons[shift.return_mode] }}</v-icon>
              </span>
              <v-icon v-if="shift.flagged" color="warning">mdi-flag</v-icon>
            </router-link>
          </span>
        </td>
        <template v-if="block.amendment">
          <td><i>Amendment</i></td>
          <td>{{ block.hours_amount }}</td>
        </template>
        <template v-else>
          <td>{{ block.start }} - {{ block.end }}</td>
          <td>{{ block.hours | hrs }}</td>
        </template>
        <td>{{ block.description }}</td>
        <td>
          <block-edit-menu :block="block" :shift="shift" @update="fetchShift(shift)"></block-edit-menu>
        </td>
      </tr>
      <shoot-amendment v-if="showAmendment" :key="shift.id + 'amendment'" :entry="shift"
                       @update="fetchShift(shift); showAmendment = false">
      </shoot-amendment>
      <tr v-if="breakdowns[shift.id]" :key="shift.uid" class="last-row">
        <td class="approved">
          <v-spacer></v-spacer>
          <v-checkbox v-model="shift.approved"
                      label="Approved" color="green" dense
                      @change="approvedChanged(shift)"></v-checkbox>
        </td>
        <td colspan="3"></td>
        <td>
          <v-icon @click="showAmendment = !showAmendment">{{ showAmendment ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
        </td>
      </tr>
      <tr :key="shift.uid + '2'">
        <td colspan="5"></td>
      </tr>
    </template>
    </tbody>
  </template>
</v-simple-table>
</template>

<script>
import BlockEditMenu from './BlockEditMenu'
import ShootAmendment from './ShootAmendment'
import * as api from '../../api'

export default {
  name: 'ShootTable',
  components: { ShootAmendment, BlockEditMenu },
  props: {
    shifts: Array
  },
  data() {
    return {
      breakdowns: {},
      showAmendment: false
    }
  },
  methods: {
    async fetchShift(shift) {
      const breakdown = await api.get('/shoot/shift/breakdown', { shift_id: shift.id })
      this.$set(this.breakdowns, shift.id, breakdown)
    },
    async approvedChanged(shift) {
      const { id, approved } = shift
      await api.put('/shift/approved', { shift_type: 'shoot', shift_id: id, approved })
    }
  },
  mounted() {
    this.shifts.forEach((shift) => {
      const breakdown = JSON.parse(shift.breakdown_json)
      this.$set(this.breakdowns, shift.id, breakdown)
    })
  }
}
</script>

<style scoped lang="scss">
.shifts table {
  th {
    &.name {
      width: 30%;
    }

    &.narrow {
      width: 17.5%;
    }

    &.description {
      width: 35%;
    }
  }

  td {
    span.internal {
      background: #ffe96c;
      margin: -5px;
      padding: 5px;
      border-radius: 7px;
    }
  }

  tr {
    &:hover {
      background: unset !important;
    }
  }

  tr.internal {
    td:not(:first-child) {
      background: #f2f2f2;
    }
  }

  tr.strike {
    td:not(:first-child, :last-child) {
      text-decoration: line-through;
    }
  }

  tr.last-row {
    margin-bottom: 1rem;
  }

  .travel-icons {
    i {
      color: #999;
    }
  }
}
</style>

<style lang="scss">
.shifts table {
  td.approved {
    .v-messages {
      display: none;
    }
  }
}
</style>
